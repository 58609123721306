import React, { useState } from 'react'
import Layout from "../components/layout";
import Seo from "../components/SEO/SEO"
import { useStaticQuery, graphql } from 'gatsby'
import '../styles/proyectos.scss'
import TarjetaProyecto from '../components/tarjetaProyecto';
import HeaderPaginas from '../components/headerPaginas'





export const query = graphql`
query {
  allStrapiProyectosecurity(sort: {order: DESC, fields: published_at}) {
    edges {
      node {
        titulo
        slug
        imagen_blanco_negro {
          url
        }
        categorias {
          filtro
          Nombre
        }
      }
    }
  }
  allStrapiCatProyecto {
    edges {
      node {
        Nombre
        filtro
        proyectos {
          id
        }
      }
    }
  }
}
`


export default function PaginaBlog(props) {

  const data = useStaticQuery(query);
  const listaCats = data.allStrapiCatProyecto.edges

  const isotope = React.useRef()

  const [filterKey, setFilterKey] = useState('*')
  const [select, setSelect] = useState('*')
  // initialize an Isotope object with configs


  React.useEffect(() => {
    const Isotope = require("isotope-layout/js/isotope");

    isotope.current = new Isotope('.contenedor', {
      itemSelector: '.proyecto',
      layoutMode: 'masonry',
      masonry: {
        columnWidth: '.proyecto'
      }
    })
    // cleanup
    return () => isotope.current.destroy()
  }, [])

  React.useEffect(() => {
    filterKey === '*'
      ? isotope.current.arrange({ filter: `*:not(.vacio)` })
      : isotope.current.arrange({ filter: `.${filterKey}` })
  }, [filterKey])


  // FUNCIÖN PARA FILTRAR LOS POSTS
  const handleFilterKeyChange = key => () => {
    const botones = document.querySelectorAll('.boton-filtro');
    let numposts = ""

    botones.forEach(boton => {
      if (boton.id !== key) {
        boton.classList.remove('activo')
      } else {
        boton.classList.add('activo')
      }
    });

    if (key !== "*") {
      numposts = document.querySelectorAll(`.${key}`).length
      if (numposts === 0) {
        // mensaje.style.display = "block"
        setFilterKey("vacio")
      } else {
        // mensaje.style.display = "none"
        setFilterKey(key)
      }
    } else {
      setFilterKey(key)
    }
  }

  function handleSelect(e) {
    
    let numposts = ""

    if (e.target.value !== "Todos") {
      numposts = document.querySelectorAll(`.${e.target.value}`).length
      if (numposts === 0) {
        // mensaje.style.display = "block"
        setFilterKey("vacio")
      } else {
        // mensaje.style.display = "none"
        setFilterKey(e.target.value)
      }
    } else {
      setFilterKey("*")
    }
    setSelect(e.target.value)
  }

  return (
    <Layout>
      <Seo pathname={props.location.pathname} />
      <HeaderPaginas titulo="Proyectos" imagen="/img/cabeceras/ProyectosBullHostSlider1_optim.jpg" />
      <div>
        <div className="filtro-posts-movil">
          <select name="filtro-cat" id="filtro-movil" value={select} onChange={handleSelect}>
            <option value="Todos" selected>Todos</option>
            {listaCats.map(({ node }, index) => {
            if (node.proyectos.length === 0) {
              return "";
            } else {

              return (
                <option
                value={node.filtro}
                key={index}
                id={node.filtro}
                >
                {node.Nombre}
              </option>
            )
          }
            })}
          </select>
        </div>
        <div className="filtro-posts">
          <button
            onClick={handleFilterKeyChange("*")}
            id="*"
            className="boton-filtro activo"
          >
            Todos
          </button>
          {listaCats.map(({ node }, index) => {
            if (node.proyectos.length === 0) {
              return "";
            } else {
            return (
              <button
                key={index}
                id={node.filtro}
                onClick={handleFilterKeyChange(`${node.filtro}`)}
                className={`boton-filtro`}
              >
                {node.Nombre}
              </button>
            )
            }
          })}
        </div>
        {/* FIN filtro de posts */}
        <div id="contenedor-posts" className="contenedor">
          {data.allStrapiProyectosecurity.edges.map(({ node }, index) => {
            return <TarjetaProyecto clave={index} post={node} />
          })}
          <div id="mensaje-vacio" className="vacio proyecto">
            <p>No hay proyectos en esta categoría</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}