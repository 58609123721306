import { Link } from 'gatsby';
import React from 'react'
import '../styles/tarjetaProyecto.scss'


export default function TarjetaProyecto(props) {

  let datos = props.post;
  let key = props.clave + 1;
  let categorias = ""
  let urlDestacada = `${process.env.API_URL}${datos.imagen_blanco_negro.url}`


  if (key < 10) {
    key = '0' + String(key);
  }

  categorias = categorias + datos.categorias.map((cat) => {
    return cat.filtro
  }).join(' ')

  return (
    <>
      <article className={`tarjeta-entrada proyecto ${categorias}`}>
        <div className="fondo-post" style={{ backgroundImage: `url(${urlDestacada})` }}>
          <div className="overlay negro"></div>
        </div>
        <div className="datos-post">
          <div className={`categorias`}>
            {datos.categorias.map((value, index) => {
              // if (value.Nombre !== "Destacado" && value.Nombre !== "Blanco") {
              return <span key={index}>{value.Nombre}</span>
              // }
            })}
          </div>
          <div className="textos">
            <h2><Link className="titulo" to={`/proyectos/${datos.slug}/`}>{datos.titulo}</Link></h2>
          </div>
        </div>
      </article>
    </>
  )
}